import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppContext } from './../../context/app-context.tsx';
import { JsonRpcResponse } from '../../lib/http.tsx';
import { GameAccountApi, GameAccountLink } from '../../api/game-account-api.tsx';
import { GameApi } from '../../api/game-api.tsx';

function AsidePromocode() {
	const { t } = useTranslation();
	const { serverId, accountList } = useAppContext();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	const [isOpenAccounts, setIsOpenAccount] = useState(false);
	const [isOpenPlayers, setIsOpenPlayers] = useState(false);
	const [playerList, setPlayerList] = useState<string[]>([]);
	const [promocode, setPromocode] = useState<string>('');
	const [account, setAccount] = useState<GameAccountLink | null>(null);
	const [player, setPlayer] = useState<string | null>(null);

	const toggleOpenAccounts = async () => {
		if (isOpenAccounts) {
			setIsOpenAccount(false);
			return;
		}

		setIsOpenPlayers(false);
		setIsOpenAccount(true);
	}

	const handleSelectAccount = async (account: GameAccountLink) => {
		setAccount(account);
		setPlayer(null);
		setIsOpenAccount(false);

		if (serverId !== null) {
			try {
				const response = await GameAccountApi.playerNames({
					serverId: serverId,
					accountLinkId: account.id
				});
				if (response.status === 200) {
					setPlayerList(response.data);
				}
			} catch (error) {

			}
		}
	}

	const toggleOpenPlayers = async () => {
		if (account === null || playerList.length <= 0)
			return;

		if (isOpenPlayers) {
			setIsOpenPlayers(false);
			return;
		}

		setIsOpenAccount(false);
		setIsOpenPlayers(true);
	}

	const handleSelectPlayer = (playerName: string) => {
		setPlayer(playerName);
		setIsOpenPlayers(false);
	}

	const handleUsePromocode = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!serverId) {
			toast.error(t('errors.server_not_selected'));
			return false;
		} else if (promocode.trim() === '') {
			toast.error(t('errors.promocode.promocode_empty'));
			return false;
		} else if (account === null) {
			toast.error(t('errors.account_not_selected'));
			return false;
		} else if (player === null || player.trim() === '') {
			toast.error(t('errors.player_not_selected'));
			return false;
		}

		try {
			const response = await GameApi.promocode({
				serverId: serverId,
				accountLinkId: account.id,
				playerName: player,
				promoCode: promocode
			});
			if (response.status !== 200 || !response.data) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data: JsonRpcResponse<boolean> = response.data;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(error)).join('\n'));
				return;
			}

			setIsModalOpen(false);
			setIsSuccessModalOpen(true);
		} catch (err) {
			toast.error(t('errors.unexpected_error'));
			return;
		}
	}

	return (
		<>
			<Link to="#" className="promocode-aside" onClick={() => setIsModalOpen(true)}>
				<span>{t('aside.promocode.link_text')}</span>
			</Link>
			{isModalOpen && (
				<div className="modal-container">
					<div className="promocode-container">
						<div className="close" onClick={() => setIsModalOpen(false)}>
							<img src="/images/icon/close.svg" alt={t('aside.promocode.close_alt')} width="24" height="24" />
						</div>
						<h2>{t('aside.promocode.modal_title')}</h2>
						<form onSubmit={(e) => handleUsePromocode(e)}>
							<div className="form-group">
								<input type="text" id="register-username" value={promocode} onChange={(e) => setPromocode(e.target.value)} placeholder={t('aside.promocode.input_placeholder')} />
							</div>
							<div className={`custom-drop-down ${isOpenAccounts ? 'open' : ''}`}>
								<div className="custom-drop-down-selected" onClick={toggleOpenAccounts}>
									<div className="custom-dropdown-item custom-dropdown">
										<div className="custom-dropdown-image">
											<img src="/images/icon/dragon.svg" alt={t('aside.promocode.dragon_alt')} />
										</div>
										<p className="custom-dropdown-name">{account ? account.accountName : t('aside.promocode.account_select')}</p>
									</div>
								</div>
								<ul className="custom-dropdown-list">
									{accountList.map((account, index) => (
										<li key={index} className="custom-dropdown-item" onClick={() => handleSelectAccount(account)}>
											<div className="custom-dropdown-image">
												<img src="/images/icon/dragon.svg" alt="dragon" />
											</div>
											<p className="custom-dropdown-name">{account.accountName}</p>
										</li>
									))}
								</ul>
							</div>
							<div className={`custom-drop-down ${isOpenPlayers ? 'open' : ''}`}>
								<div className="custom-drop-down-selected" onClick={toggleOpenPlayers}>
									<div className="custom-dropdown-item custom-dropdown">
										<div className="custom-dropdown-image">
											<img src="/images/icon/dragon.svg" alt={t('aside.promocode.dragon_alt')} />
										</div>
										<p className="custom-dropdown-name">{player ? player : t('aside.promocode.character_select')}</p>
									</div>
								</div>
								<ul className="custom-dropdown-list">
									{playerList.map((playerName, index) => (
										<li key={index} className="custom-dropdown-item" onClick={() => handleSelectPlayer(playerName)}>
											<div className="custom-dropdown-image">
												<img src="/images/icon/dragon.svg" alt="dragon" />
											</div>
											<p className="custom-dropdown-name">{playerName}</p>
										</li>
									))}
								</ul>
							</div>
							<button type="submit" className="cta-secondary big">
								<span>{t('aside.promocode.apply_button')}</span>
							</button>
						</form>
					</div>
				</div>
			)}
			{isSuccessModalOpen && (
				<div className="modal-container">
					<div className="promocode-container">
						<div className="close" onClick={() => setIsSuccessModalOpen(false)}>
							<img src="/images/icon/close.svg" alt="close" width="24" height="24" />
						</div>
						<h2>{t('aside.promocode.success_modal.title')}</h2>
					</div>
				</div>
			)}
		</>
	);
}

export default AsidePromocode;