import { HTTP, JsonRpcResponse } from '../lib/http.tsx';

const CONTROLLER = "/game";

export const GameApi = {
	serverList: () => { return HTTP.get<ServerData[]>(CONTROLLER + '/server-list'); },
	promocode: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/use-promocode', { ...data }); }
}

export interface ServerData {
	id: number,
	name: string,
	rate: number,
	status: boolean
};