import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppContext } from '../../context/app-context.tsx';
import { JsonRpcResponse } from '../../lib/http.tsx';
import { GameAccountApi, GameAccountLink } from '../../api/game-account-api.tsx';

interface PropsAccount {
	info: GameAccountLink;
}

interface ModalProps {
	title: string;
	accountName: string;
	onClose: () => void;
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const Modal: React.FC<ModalProps> = ({ title, accountName, onClose, onSubmit }) => (
	<div className="modal-container">
		<form className="form" onSubmit={onSubmit}>
			<div className="form-header">
				<p>{title}</p>
				<div className="close" onClick={onClose}>
					<img src="/images/icon/close.svg" alt="close" width="24" height="24" />
				</div>
			</div>
			<div className="form-group">
				<label htmlFor="account-name">Выбранный аккаунт</label>
				<p className="server-name">{accountName}</p>
			</div>
			<button className="cta-secondary">
				<span>Подтвердить</span>
			</button>
			<div className="annotation">
				<p>Письмо с дальнейшими инструкциями будет отправлено на ваш почтовый адрес! Пожалуйста, проверьте входящие письма.</p>
			</div>
		</form>
	</div>
);

function Account({ info }: PropsAccount) {
	const { t } = useTranslation();
	const { serverId } = useAppContext();
	const [modals, setModals] = useState({
		resetPin: false,
		resetLock: false,
		changePassword: false,
	});

	const toggleModal = (modalName: keyof typeof modals, isOpen: boolean) => {
		setModals((prev) => ({ ...prev, [modalName]: isOpen }));
	};

	const handleAction = async (
		action: () => Promise<AxiosResponse<JsonRpcResponse<boolean>, any>>,
		onClose: () => void
	) => {
		try {
			const response = await action();
			if (response.status !== 200 || !response.data) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data = response.data;
			if (data.status !== 'success') {
				toast.error(data.errors.map((error) => t(error)).join('\n'));
				return;
			}

			toast("Письмо успешно отправлено!");
			onClose();
		} catch (err) {
			toast.error(t('errors.unexpected_error'));
		}
	};

	const handleResetPin = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!serverId) {
			toast.error(t('Не выбран сервер!'));
			return;
		}

		handleAction(
			() => GameAccountApi.pinReset({ serverId, accountLinkId: info.id }),
			() => toggleModal('resetPin', false)
		);
	};

	const handleResetLock = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		
		if (!serverId) {
			toast.error(t('Не выбран сервер!'));
			return;
		}

		handleAction(
			() => GameAccountApi.resetLock({ serverId, accountLinkId: info.id }),
			() => toggleModal('resetLock', false)
		);
	};

	const handleChangePassword = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		handleAction(
			() => GameAccountApi.changePassword({ serverId, accountLinkId: info.id }),
			() => toggleModal('changePassword', false)
		);
	};

	return (
		<>
			<div className="table-row">
				<div className="col item">{info.accountName}</div>
				<div className="col date">{info.created.split('T')[0]}</div>
				<div className="col action">
					<span className="icon gear">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M16.4941 6.83848C15.2407 7.56212 14.2166 6.9716 14.2121 5.5246C14.2084 4.68655 13.5299 4.00722 12.6919 4.01095L11.0981 4.01706C10.3631 4.00744 9.76876 4.60912 9.75914 5.34408L9.75893 5.51963C9.76339 6.96664 8.73852 7.55834 7.48069 6.83497L7.32855 6.74738C6.7011 6.38024 5.88283 6.59414 5.52368 7.23545L4.7215 8.61265C4.29925 9.33655 4.55235 10.2707 5.27625 10.6929C6.53009 11.4094 6.5294 12.5916 5.27603 13.3152C4.55585 13.731 4.30015 14.6635 4.71995 15.3906L5.42359 16.6093C5.83939 17.3295 6.77182 17.5852 7.49891 17.1654C8.75229 16.4418 9.77647 17.0323 9.774 18.4833C9.77773 19.3213 10.4562 20.0007 11.2942 19.9969L12.888 19.9908C13.623 20.0004 14.2173 19.3988 14.227 18.6638L14.2272 18.4882C14.2227 17.0412 15.2476 16.4495 16.5054 17.1729L16.6575 17.2605C17.2988 17.6196 18.1171 17.4057 18.4763 16.7644L19.2784 15.3872C19.7007 14.6633 19.4476 13.7292 18.7277 13.3139C17.4699 12.5905 17.4705 11.4083 18.7239 10.6846C19.4441 10.2688 19.6998 9.33641 19.28 8.60932L18.5764 7.39056C18.1536 6.67439 17.2212 6.41869 16.4941 6.83848ZM13.2959 14.2525C12.0563 14.9681 10.4616 14.5408 9.74597 13.3013C9.03033 12.0618 9.45763 10.467 10.6972 9.7514C11.9367 9.03576 13.5314 9.46306 14.2471 10.7026C14.9627 11.9421 14.5354 13.5368 13.2959 14.2525Z"
								fill="#C0C3CD"
							/>
						</svg>
					</span>
					<ul className="submenu">
						<li className="submenu-item">
							<Link to="#" className="submenu-link" onClick={() => toggleModal('resetPin', true)}>
								<img src="/images/icon/locker.svg" alt="" width="18" height="18" />
								Сброс пин-кода
							</Link>
						</li>
						<li className="submenu-item">
							<Link to="#" className="submenu-link" onClick={() => toggleModal('resetLock', true)}>
								<img src="/images/icon/locker.svg" alt="" width="18" height="18" />
								Сбросить PIN передачь
							</Link>
						</li>
						<li className="submenu-item">
							<Link to="#" className="submenu-link" onClick={() => toggleModal('changePassword', true)}>
								<img src="/images/icon/key.svg" alt="" width="18" height="18" />
								Изменить пароль
							</Link>
						</li>
					</ul>
				</div>
			</div>

			{modals.resetPin && (
				<Modal
					title="Сбросить PIN"
					accountName={info.accountName}
					onClose={() => toggleModal('resetPin', false)}
					onSubmit={handleResetPin}
				/>
			)}

			{modals.resetLock && (
				<Modal
					title="Сбросить PIN передачи"
					accountName={info.accountName}
					onClose={() => toggleModal('resetLock', false)}
					onSubmit={handleResetLock}
				/>
			)}

			{modals.changePassword && (
				<Modal
					title="Смена пароля"
					accountName={info.accountName}
					onClose={() => toggleModal('changePassword', false)}
					onSubmit={handleChangePassword}
				/>
			)}
		</>
	);
}

export default Account;