import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../context/app-context.tsx';
import { GameApi, ServerData } from '../../api/game-api.tsx';

function AsideServer() {
	const { t } = useTranslation();
	const { serverId, setServerId } = useAppContext();
	const [isOpen, setIsOpen] = useState(false);
	const [serverList, setServerList] = useState<ServerData[]>([]);

	const handleChange = (server: number) => {
		setServerId(server);
		setIsOpen(false);
	};

	const updateServerList = async () => {
		try {
			const response = await GameApi.serverList();
			if (response.status === 200) {
				setServerList(response.data);
			}
		} catch (error) {

		}
	}
	
	const server = serverList.find(server => server.id === serverId);

	useEffect(() => {
		updateServerList();
	}, []);

	return (
		<div className={`custom-drop-down ${isOpen ? 'open' : ''}`}>
			<div className="custom-drop-down-selected" onClick={() => setIsOpen(!isOpen)}>
				<div className="custom-dropdown-item">
					<img src="/images/icon/spring-crown.svg" alt="spring crown" />
					<div className="server-info">
						{server ? (<>
							<p className="server-name">
								{server.name} <span className={`status ${server.status ? "online" : "offline"}`}></span>
							</p>
							<p className="server-rate">
								{t('aside.server.server')} <span className="accent">X{server.rate}</span>
							</p>
						</>) : (<p className="server-name">{t('aside.server.select_server')}</p>)}
					</div>
				</div>
			</div>
			<ul className="custom-dropdown-list">
				{serverList.map((server, index) => (
					<li key={index} className="custom-dropdown-item" onClick={() => handleChange(server.id)}>
						<img src="/images/icon/spring-crown.svg" alt="spring crown" />
						<div className="server-info">
							<p className="server-name">
								{server.name} <span className={`status ${server.status ? "online" : "offline"}`}></span>
							</p>
							<p className="server-rate">{t('aside.server.server')} <span className="accent">X{server.rate}</span></p>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
}

export default AsideServer;