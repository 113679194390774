import { HTTP, JsonRpcResponse } from '../lib/http.tsx';

const CONTROLLER = '/auth';

export const AuthApi = {
	login: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<IAuthResponse>>(CONTROLLER + '/login', { ...data }); },
	registration: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/registration', { ...data }); },
	confirmEmail: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<IConfirmMailResponse>>(CONTROLLER + '/confirm-email', { ...data }); },
	resendConfirmEmail: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/resend-confirm-email', { ...data }); },
	changePassword: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/password/change', { ...data }); },
	changePasswordCheck: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/password/check-token', { ...data }); },
	changePasswordComplete: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/password/change-complete', { ...data }); }
}

export interface IAuthResponse {
	userName: string,
	email: string,
	accessToken: string,
	refreshToken: string
}

export interface IConfirmMailResponse {
	userName: string,
	email: string,
	password: string
}