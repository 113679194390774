const itemIcons: Record<number, string> = {
	1: "/images/item/etc_nias_gold_wyvern_i00.png",
	2: "/images/item/etc_nias_blood_medusa_i00.png",
	3: "/images/item/etc_nias_silver_fairy_i00.png",
	4: "/images/item/etc_coin_of_fair_i00.png",
	5: "/images/item/bm_einhasad_coin.png",
	6: "/images/item/etc_lottery_card_i00.png",
};

export function getIconById(id: number): string {
	return itemIcons[id];
}