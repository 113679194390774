import { HTTP, JsonRpcResponse } from '../lib/http.tsx';

const CONTROLLER = "/game-account";

export const GameAccountApi = {
	accountList: () => { return HTTP.post<AccountInfoResponse>(CONTROLLER + '/account-list'); },
	addAccount: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<GameAccountLink>>(CONTROLLER + '/add', { ...data }); },
	linkAccount: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<GameAccountLink>>(CONTROLLER + '/link', { ...data }); },
	playerNames: <T,>(data: T) => { return HTTP.post<string[]>(CONTROLLER + '/player-names', { ...data }); },
	pinReset: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/pin/reset', { ...data }); },
	pinResetComplete: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/pin/reset-complete', { ...data }); },
	resetLock: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/pin-lock/reset', { ...data }); },
	resetLockComplete: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/pin-lock/reset-complete', { ...data }); },
	changePassword: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/password/change', { ...data }); },
	changePasswordCheck: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/password/check-token', { ...data }); },
	changePasswordComplete: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/password/change-complete', { ...data }); }
}

export interface GameAccountLink {
	id: number,
	accountName: string,
	created: string
}

export interface AccountInfoResponse {
	accountLinkList: GameAccountLink[],
	maxAccount: number
}