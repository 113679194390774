import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { JsonRpcResponse } from '../../lib/http.tsx';
import { AuthApi } from '../../api/auth-api.tsx';
import config from '../../config.tsx';

function PasswordRecovery() {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { t } = useTranslation();
	const [success, setSuccess] = useState(false);
	const [email, setEmail] = useState('');

	function validate() {
		let errorMessage = '';
		if (email.trim() === '')
			errorMessage += '• ' + t("Введите почту!") + '\n';
		else if (email.trim() === '')
			errorMessage += '• ' + t("Введите почту!") + '\n';
		return errorMessage.trim() || null;
	}

	const handleConfirmSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const errorMsg = validate();
		if (errorMsg !== null) {
			toast.error(errorMsg, { style: { whiteSpace: "pre-line" } });
			return;
		}

		let recaptchaToken: string | null = null;
		if (config.ENABLE_RECAPTCHA && config.RE_CAPTCHA_KEY) {
			if (!executeRecaptcha) {
				toast.error(t('errors.unexpected_error'));
				return;
			}

			recaptchaToken = await executeRecaptcha('login');
			if (!recaptchaToken) {
				toast.error(t('errors.confirm_you_are_not_a_robot'));
				return;
			}
		}

		try {
			const response = await AuthApi.changePassword({ email, recaptchaToken });
			if (response.status !== 200 || !response.data) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data = response.data as JsonRpcResponse<boolean>;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(error)).join('\n'));
				return;
			}

			toast("Письмо успешно отправлено!");
			setSuccess(true);
		} catch (err) {
			toast.error(t('errors.unexpected_error'));
			return;
		}
	}

	if (success) {
		return (
			<>
				<div className="bg login"></div>
				<ToastContainer style={{ fontSize: '14px' }} />
				<div className="notification-full">
					<div className="notification-content">
						<h2 className="accent">Восстановление пароля</h2>
						<p>
							На Ваш почтовый адрес <span className="accent">{email}</span> направлено письмо с дальнейшей инструкцией.
						</p>
						<p>
							Пройдите по ссылке в письме для изменения пароля. Если письмо не пришло проверьте папку спам.
						</p>
						<div className="link-group">
							<Link to="/login">Вход</Link>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="bg login"></div>
			<ToastContainer style={{ fontSize: '14px' }} />
			<div className="login">
				<div className="logo">
					<img src="/images/logo.svg" alt="Genesis Lineage 2" />
					<span>Genesis World</span>
				</div>
				<div className="login-container">
					<div className="notification-content">
						<h2 className="accent">Восстановление пароля</h2>
					</div>
					<form id="login" onSubmit={handleConfirmSubmit} className="form login-form active">
						<div className="form-group login">
							<label htmlFor="login-email">Почта</label>
							<input type="text" onChange={(e) => setEmail(e.target.value)} id="login-email" placeholder="Ваша почта" />
						</div>
						<p className="referal-info">На почту вам будет отправлено письмо с подтверждением о восстановление пароля!</p>
						<button type="submit" className="cta-secondary"><span>Отправить</span></button>
					</form>
					<div className="link-group">
						<Link to="/login">Вход</Link>
						<Link to="#">Сброс 2FA</Link>
						<Link to="/confirm-request-mail">Отправить письмо о подтверждении email</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default PasswordRecovery;