import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../context/app-context.tsx';
import Account from './game-account/account.tsx';
import AddAccountModal from './game-account/account-add.tsx';
import LinkAccountModal from './game-account/account-link.tsx';

function GameAccount() {
	const { t } = useTranslation();
	const { accountList } = useAppContext();
	const [isModalOpen, setIsModalAddOpen] = useState(false);
	const [isModalLinkAccountOpen, setIsModalLinkOpen] = useState(false);

	return (
		<>
			<div className="dark-container game-accounts">
				<div className="container-head">
					<p className="title">Игровые аккаунты</p>
					<div className="ctas">
						<div className="add-new-account" onClick={() => setIsModalAddOpen(true)}>
							<img src="/images/icon/add.svg" alt="add" />
						</div>
						<div className="link-account" onClick={() => setIsModalLinkOpen(true)}>
							<img src="/images/icon/link.svg" alt="link" />
						</div>
					</div>
				</div>
				<div className="table-container accounts">
					<div className="table-header">
						<p className="col item" data-account="">Аккаунт</p>
						<p className="col date">Дата привязки</p>
						<p className="col action">Действие</p>
					</div>
					<div className="table-body">
						{accountList.map((account, index) => (
							<Account key={index} info={account} />
						))}
					</div>
				</div>
			</div>
			{isModalOpen && <AddAccountModal onClose={() => setIsModalAddOpen(false)} />}
			{isModalLinkAccountOpen && <LinkAccountModal onClose={() => setIsModalLinkOpen(false)} />}
		</>
	);
}

export default GameAccount;