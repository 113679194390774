import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { JsonRpcResponse } from "../../lib/http.tsx";
import { GameAccountApi } from "../../api/game-account-api.tsx";

function GameResetLockPin() {
	const navigate = useNavigate();
	const { code } = useParams<{ code?: string }>();
	const { t } = useTranslation();
	const [isModalSuccess, setIsModalSuccess] = useState(false);

	const resetPinComplete = useCallback(async (token: string) => {
		try {
			const response = await GameAccountApi.resetLockComplete({ token });
			if (response.status !== 200 || !response.data) {
				toast.error(t('errors.server_request_failed'));
				navigate('/');
				return;
			}

			const data = response.data as JsonRpcResponse<boolean>;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(`errors.${error}`)).join('\n'));
				navigate('/');
				return;
			}

			setIsModalSuccess(true);
		} catch (err: any) {
			toast.error(t('errors.unexpected_error'));
			navigate('/');
		}
	}, [navigate, t]);

	const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		setIsModalSuccess(false);
		navigate('/');
	}

	useEffect(() => {
		if (code) {
			resetPinComplete(code);
		}
	}, [code, resetPinComplete]);

	return (
		<>
			{isModalSuccess && (
				<div className="modal-container">
					<form className="form">
						<div className="form-header">
							<p>Сбросить PIN передачи</p>
							<div className="close" onClick={handleClose}>
								<img src="/images/icon/close.svg" alt="close" width="24" height="24" />
							</div>
						</div>
						<div className="annotation">
							<p>
								Вы успешно сбросили PIN-код запрещающий передачу предметов!
							</p>
						</div>
					</form>
				</div>
			)}
		</>
	);
}

export default GameResetLockPin;