import React, { createContext, useContext, useState, useEffect } from 'react';
import { AccountInfoResponse, GameAccountApi, GameAccountLink } from '../api/game-account-api.tsx';
import { IAuthResponse } from '../api/auth-api.tsx';

interface AppContextProps {
	serverId: number | null;
	setServerId: (id: number) => void;
	accountList: GameAccountLink[];
	setAccountList: React.Dispatch<React.SetStateAction<GameAccountLink[]>>;
	maxAccount: number;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('useServerContext must be used within a ServerProvider');
	}

	return context;
};

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [serverId, setServerId] = useState<number | null>(null);
	const [user, setUser] = useState<IAuthResponse | null>(null);
	const [accountList, setAccountList] = useState<GameAccountLink[]>([]);
	const [maxAccount, setMaxAccountLimited] = useState<number>(0);

	const loadAccountList = async () => {
		try {
			const response = await GameAccountApi.accountList();
			if (response.status === 200) {
				const data = response.data as AccountInfoResponse;
				setAccountList(data.accountLinkList);
				setMaxAccountLimited(data.maxAccount);
			}
		} catch (error) {

		}
	}

	useEffect(() => {
		if (user) {
			const storedServerId = localStorage.getItem('selectedServer');
			if (storedServerId) {
				setServerId(Number(storedServerId));
			}

			loadAccountList();
		}
	}, [user]);

	useEffect(() => {
		if (serverId !== null) {
			localStorage.setItem('selectedServer', serverId.toString());
		}
	}, [serverId]);

	useEffect(() => {
		const user: IAuthResponse = JSON.parse(localStorage.getItem('user') || 'null');
		if (user) {
			setUser(user);
		}
	}, []);

	return (
		<AppContext.Provider value={{
			serverId,
			setServerId,
			accountList,
			setAccountList,
			maxAccount
		}}>
			{children}
		</AppContext.Provider>
	);
};