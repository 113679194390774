import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { StoreApi, StoreItem } from '../api/store-api.tsx';
import { getIconById } from '../utils/store-util.tsx';
import { useAppContext } from '../context/app-context.tsx';
import { JsonRpcResponse } from '../lib/http.tsx';
import { AccountInfoResponse, GameAccountApi, GameAccountLink } from '../api/game-account-api.tsx';

interface ICartItem {
	id: number,
	price: number,
	count: number
}

interface IPurchaseItem {
	id: number;
	quantity: number;
}

function Shop() {
	const { t } = useTranslation();
	const { serverId, accountList } = useAppContext();
	const [itemList, setItemList] = useState<StoreItem[]>([]);
	const [itemCartList, setItemCartList] = useState<ICartItem[]>([]);

	const [isOpenAccounts, setIsOpenAccount] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState<GameAccountLink | null>(null);

	const [isOpenPlayers, setIsOpenPlayers] = useState(false);
	const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);
	const [playerList, setPlayerList] = useState<string[]>([]);

	const toggleOpen = () => {
		setIsOpenAccount(!isOpenAccounts);
	};

	const toggleOpenPlayers = () => {
		if (isOpenPlayers)
			setIsOpenPlayers(false);
		else
			updatePlayerList();
	};

	async function updatePlayerList() {
		try {
			const response = await GameAccountApi.playerNames({
				serverId: serverId,
				accountLinkId: selectedAccount?.id
			});
			if (response.status === 200) {
				const data = response.data as string[];
				setPlayerList(data);
			}
		} catch (error) {

		}

		setIsOpenPlayers(true);
	}

	async function updateItemList(id: number) {
		try {
			const response = await StoreApi.itemList(id);
			if (response.status === 200) {
				setItemList(response.data);
			}
		} catch (error) {

		}
	}

	const handleIncrease = (itemId: number) => {
		setItemCartList((prev) => {
			const existingItem = prev.find((cartItem) => cartItem.id === itemId);
			if (existingItem) {
				return prev.map((cartItem) => cartItem.id === itemId ? { ...cartItem, count: cartItem.count + 1 } : cartItem);
			}

			const item = itemList.find((i) => i.itemId === itemId);
			if (item) {
				return [...prev, { id: item.itemId, price: item.price, count: 1 }];
			}
			return prev;
		});
	};

	const handleDecrease = (itemId: number) => {
		setItemCartList((prev) =>
			prev.map((cartItem) => cartItem.id === itemId ? { ...cartItem, count: cartItem.count - 1 } : cartItem)
				.filter((cartItem) => cartItem.count > 0)
		);
	};

	const handleDelete = (id: number) => {
		setItemCartList((prev) => prev.filter((cartItem) => cartItem.id !== id));
	}

	const handleInputChange = (itemId: number, value: number) => {
		setItemCartList((prev) => {
			if (value <= 0) {
				return prev.filter((cartItem) => cartItem.id !== itemId);
			}

			const existingItem = prev.find((cartItem) => cartItem.id === itemId);
			if (existingItem) {
				return prev.map((cartItem) => cartItem.id === itemId ? { ...cartItem, count: value } : cartItem);
			}

			const item = itemList.find((i) => i.itemId === itemId);
			if (item) {
				return [...prev, { id: item.itemId, price: item.price, count: value }];
			}
			return prev;
		});
	};

	async function handleBuy(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if (serverId === null) {
			toast.error("Не выбран сервер!");
			return;
		}

		if (selectedAccount === null) {
			toast.error("Не выбран игровой аккаунт!");
			return;
		}

		if (selectedPlayer === null) {
			toast.error("Не выбран игровой аккаунт!");
			return;
		}

		const purchaseItem: IPurchaseItem[] = itemCartList.map((item) => ({
			id: item.id,
			quantity: item.count,
		}));

		try {
			const response = await StoreApi.buy({
				itemIdList: purchaseItem,
				serverId: serverId,
				accountLinkId: selectedAccount.id,
				playerName: selectedPlayer
			});

			if (response.status !== 200 || !response.data) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data: JsonRpcResponse<boolean> = response.data;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(error)).join('\n'));
				return;
			}

			toast("Вы успешно купили товар!");
		} catch (err) {
			toast.error(t('errors.unexpected_error'));
		}
	}

	const totalSum = useMemo(() => {
		return itemCartList.reduce((sum, item) => sum + item.price * item.count, 0);
	}, [itemCartList]);

	useEffect(() => {
		if (serverId !== null)
			updateItemList(serverId);
	}, [serverId]);

	function handleSelectAccount(account: GameAccountLink): void {
		setSelectedAccount(account);
		setIsOpenAccount(false);
	}

	function handleSelectPlayer(playerName: string): void {
		setSelectedPlayer(playerName);
		setIsOpenPlayers(false);
	}

	return (
		<>
			<div className="bg home"></div>
			<div className="dark-container">
				<div className="container-head">
					<p className="title">Внутригровые Предметы / Услуги</p>
					<a href="" className="head-link">
						<img src="/images/icon/calendar.svg" alt="calendar" />
						История покупок
					</a>
				</div>
				<form className="form-shop" onSubmit={handleBuy}>
					<div className="table-container">
						<div className="table-header">
							<p className="col item">Предмет / Услуга</p>
							<p className="col description">Описание</p>
							<p className="col quantity">Количество</p>
							<p className="col coins">Цена</p>
						</div>
						<div className="table-body">
							{itemList.map((item, index) => (
								<div key={index} className="table-row">
									<p className="col item">
										<img src={getIconById(item.itemId)} alt="item" width="32" height="32" />
										{t("store.item_slot.name." + item.itemId) + " (" + item.batchSize + ")"}
									</p>
									<p className="col description">{t("store.item_slot.description." + item.itemId)}</p>
									<p className="col quantity">
										<span className="decrease" onClick={() => handleDecrease(item.itemId)}><img src="/images/icon/arr.svg" alt="" /></span>
										<input
											type="number"
											className="inp-quantity"
											id="quantity"
											value={itemCartList.find((cartItem) => cartItem.id === item.itemId)?.count || ''}
											onChange={(e) => handleInputChange(item.itemId, parseInt(e.target.value, 10) || 0)}
										/>
										<span className="increase" onClick={() => handleIncrease(item.itemId)}><img src="/images/icon/arr.svg" alt="" /></span>
									</p>
									<p className="col coins">
										<img src="/images/icon/coin.svg" alt="coin" />
										{item.price}
									</p>
								</div>
							))}
						</div>
					</div>
					<div className="container-head">
						<p className="title">Корзина</p>
					</div>
					{itemCartList.map((item, index) => (
						<div key={index} className="table-row">
							<p className="col item">
								<img src={getIconById(item.id)} alt="item" width="32" height="32" />
								{t("store.item_slot.name." + item.id)}
							</p>
							<p className="col item counts">
								<span className="item-total">
									<img src="/images/icon/coin.svg" alt="coin" width="14" height="14" />
									{item.price} x {item.count} = {item.price * item.count}
								</span>
								<span className="del" onClick={() => handleDelete(item.id)}>
									<img src="/images/icon/del.svg" alt="del" width="24" height="24" />
								</span>
							</p>
						</div>
					))};
					<div className="donat-total">
						<p>Итоговая стоимость заказа:</p>
						<p className="coins">
							<img src="/images/icon/coin.svg" alt="coins" />
							<strong className="accent">{totalSum}</strong>
						</p>
					</div>
					<div className="ctas">
						<div className={`custom-drop-down ${isOpenAccounts ? 'open' : ''}`}>
							<div className="custom-drop-down-selected" onClick={toggleOpen}>
								<div className="custom-dropdown-item custom-dropdown">
									<div className="custom-dropdown-image">
										<img src="/images/icon/dragon.svg" alt="spring crown" />
									</div>
									<p className="custom-dropdown-name">{selectedAccount ? selectedAccount.accountName : 'Выбор аккаунта'}</p>
								</div>
							</div>
							<ul className="custom-dropdown-list">
								{accountList.map((account, index) => (
									<li key={index} className="custom-dropdown-item" onClick={() => handleSelectAccount(account)}>
										<div className="custom-dropdown-image">
											<img src="/images/icon/dragon.svg" alt="dragon" />
										</div>
										<p className="custom-dropdown-name">{account.accountName}</p>
									</li>
								))}
							</ul>
						</div>
						<div className={`custom-drop-down ${isOpenPlayers ? 'open' : ''}`}>
							<div className="custom-drop-down-selected" onClick={toggleOpenPlayers}>
								<div className="custom-dropdown-item custom-dropdown">
									<div className="custom-dropdown-image">
										<img src="/images/icon/dragon.svg" alt="spring crown" />
									</div>
									<p className="custom-dropdown-name">{selectedPlayer || 'Выбор персонажа'}</p>
								</div>
							</div>
							<ul className="custom-dropdown-list">
								{playerList.map((playerName, index) => (
									<li key={index} className="custom-dropdown-item" onClick={() => handleSelectPlayer(playerName)}>
										<div className="custom-dropdown-image">
											<img src="/images/icon/dragon.svg" alt="dragon" />
										</div>
										<p className="custom-dropdown-name">{playerName}</p>
									</li>
								))}
							</ul>
						</div>
						<button type="submit" className="cta">Подтвердить заказ</button>
					</div>
				</form>
			</div>
		</>
	);
}

export default Shop;