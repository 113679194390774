import { HTTP, JsonRpcResponse } from '../lib/http.tsx';

const CONTROLLER = "/store";

export const StoreApi = {
	itemList: (serverId: number) => { return HTTP.get<StoreItem[]>(CONTROLLER + '/item-list/' + serverId); },
	buy: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/buy', { ...data }); }
}

export interface StoreItem {
	itemId: number;
	price: number;
	maxAmount: number;
	batchSize: number;
}