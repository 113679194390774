import React, { useRef, useEffect } from 'react';

const SnowfallCanvas: React.FC = () => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext('2d');
		if (!ctx) return;

		const snowflakes: Snowflake[] = [];
		const numSnowflakes = 250;
		const colors = ['#ffffff', '#cce7ff', '#e6f7ff'];
		const symbols = ['❄️', '🎄', '🎁', '✨', '🔔', '⛄', '🎅', '🎆'];

		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;

		class Snowflake {
			canvas: HTMLCanvasElement;
			ctx: CanvasRenderingContext2D;
			x: number;
			y: number;
			size: number;
			radius: number;
			speed: number;
			angle: number;
			color: string;
			randomValue: number;

			constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
				this.canvas = canvas;
				this.ctx = ctx;
				this.x = Math.random() * canvas.width;
				this.y = Math.random() * canvas.height;
				this.radius = Math.random() * 3 + 1;
				this.speed = Math.random() * 2 + 1;
				this.size = Math.random() * 24 + 1;
				this.angle = Math.random() * 2 * Math.PI;
				this.color = colors[Math.floor(Math.random() * colors.length)];
				this.randomValue = Math.floor(Math.random() * 2);
			}

			update() {
				this.y += this.speed;
				this.x += Math.sin(this.angle) * 0.5;
				this.angle += 0.02;

				if (this.y > this.canvas.height) {
					this.y = -this.radius;
					this.x = Math.random() * this.canvas.width;
				}
			}

			draw() {
				if (this.randomValue === 0) {
					this.ctx.font = `15px Arial`;
					this.ctx.fillStyle = '#fff';
					this.ctx.fillText("❉", this.x, this.y);
				} else if (this.randomValue === 1) {
					this.ctx.font = `15px Arial`;
					this.ctx.fillStyle = '#fff';
					this.ctx.fillText(symbols[0], this.x, this.y);
				} else {
					this.ctx.beginPath();
					this.ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
					this.ctx.fillStyle = this.color;
					this.ctx.fill();
				}
			}

			getOpacity(): string {
				const opacity = Math.min(1, this.y / this.canvas.height + 0.2);
				return `rgba(255, 255, 255, ${opacity})`;
			}
		}

		const initSnowflakes = () => {
			for (let i = 0; i < numSnowflakes; i++) {
				snowflakes.push(new Snowflake(canvas, ctx));
			}
		};

		const render = () => {
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			snowflakes.forEach((flake) => {
				flake.update();
				flake.draw();
			});
			requestAnimationFrame(render);
		};

		initSnowflakes();
		render();

		const handleResize = () => {
			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none', zIndex: -1 }} />
	);
};

export default SnowfallCanvas;