import config from './../config.tsx';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { jwtDecode, JwtPayload } from "jwt-decode";
import i18n from 'i18next';
import { IAuthResponse } from '../api/auth-api.tsx'

export const HTTP = axios.create({
	baseURL: config.API_URL
});

HTTP.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
	const payload: IAuthResponse = JSON.parse(localStorage.getItem('user') || '{}');
	const token = payload.accessToken || '';
	if (token.length !== 0) {
		const decodedToken: JwtPayload = jwtDecode(token);
		const expirationTime = decodedToken.exp || 0;
		if (expirationTime * 1000 < Date.now()) {
			localStorage.clear();
			sessionStorage.clear();
			window.location.href = '/login';
			return Promise.reject('JWT token expired');
		}

		config.headers['Authorization'] = `Bearer ${token}`;
	}

	config.headers['Accept-Language'] = i18n.language;
	return config;
}, function (error) {
	return Promise.reject(error);
});

HTTP.interceptors.response.use(function (response: AxiosResponse) {
	return response;
}, function (error) {
	if (error.response && error.response.status === 401) {
		const payload: IAuthResponse = JSON.parse(localStorage.getItem('user') || '{}');
		const token = payload.accessToken || '';
		if (token.length !== 0) {
			localStorage.clear();
			sessionStorage.clear();
			window.location.href = '/login';
		}
	}

	return Promise.reject(error);
});


export enum JsonRpcStatus {
	SUCCESS = 'success',
	ERROR = 'error'
}

export interface JsonRpcResponse<T> {
	status: JsonRpcStatus;
	errors: string[];
	body: T
}