import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserPayment, PayApi, PaymentStatus } from '../api/pay-api.tsx';
import { getCurrencySymbol, getImagePathForMethodType, getImagePathForStatus } from '../utils/payment-util.tsx';

function DonationHistory() {
    const { t } = useTranslation();
	const [paymentList, setPaymentList] = useState<IUserPayment[]>([]);

	const update = async () => {
		try {
			const response = await PayApi.history();
			if (response.status === 200) {
				setPaymentList(response.data);
			}
		} catch (error) {

		}
	}

	const totalCoins = paymentList.reduce((sum, payment) => {
		if (payment.status !== PaymentStatus.Succeed) {
			return sum;
		}

		return sum + payment.totalCoins * (1 + (payment.appliedBonusRate ?? 0));
	}, 0);

	useEffect(() => {
		update();
	}, []);

	return (
		<>
			<div className="bg story"></div>
			<div className="dark-container">
				<div className="container-head">
					<p className="title">{t('content.donate-history.title')}</p>
				</div>
				<div className="table-container">
					<div className="table-header">
						<p className="col id">{t('content.donate-history.id_column')}</p>
						<p className="col platforma">{t('content.donate-history.platform_column')}</p>
						<p className="col quantity">{t('content.donate-history.quantity_column')}</p>
						<p className="col date">{t('content.donate-history.created_column')}</p>
						<p className="col date">{t('content.donate-history.closed_column')}</p>
						<p className="col coins">{t('content.donate-history.coins_column')}</p>
						<p className="col status">{t('content.donate-history.status_column')}</p>
					</div>
					<div className="table-body">
						{paymentList.map((payment, index) => (
							<div key={index} className="table-row">
								<p className="col id">{payment.id}</p>
								<p className="col platforma">
									<img src={getImagePathForMethodType(payment.methodType)} alt={payment.methodType} />
								</p>
								<p className="col quantity"><strong className="accent">{getCurrencySymbol(payment.currency)}</strong> {payment.amount}</p>
								<p className="col date">
									{payment.created.split('T')[0]} <time dateTime="">{payment.created.split('T')[1]}</time>
								</p>
								<p className="col date">
									{payment.closed?.split('T')[0]} <time dateTime="">{payment.closed ? payment.closed?.split('T')[1] : "-"}</time>
								</p>
								<p className="col coins">
									<img src="/images/icon/coin.svg" alt="coin" />
									<span>{Math.floor(payment.totalCoins)}</span>
									{payment.appliedBonusRate !== null && (
										<sup title={t('content.donate-history.bonus_label')} className="accent">
											+ {Math.floor(payment.appliedBonusRate * 100)}%
										</sup>
									)}
								</p>
								<p className="col status">
									<img src={getImagePathForStatus(payment.status)} alt="check" />
								</p>
							</div>
						))}
					</div>
					<div className="donat-total">
						<p>{t('content.donate-history.total_donated')}</p>
						<p className="coins">
							<img src="/images/icon/coin.svg" alt="coins" />
							<strong className="accent">{totalCoins}</strong>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default DonationHistory;