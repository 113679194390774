import { HTTP, JsonRpcResponse } from '../lib/http.tsx';

const CONTROLLER = "/pay";

export const PayApi = {
	balance: () => { return HTTP.get<number>(CONTROLLER + '/balance'); },
	paymentSystems: () => { return HTTP.get<PaymentMethod[]>(CONTROLLER + '/payment-systems'); },
	donate: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<string>>(CONTROLLER + '/donate', { ...data }); },
	history: () => { return HTTP.get<IUserPayment[]>(CONTROLLER + '/history'); }
}

export interface IUserPayment {
	id: number;
	currency: CurrencyType;
	amount: number;
	created: string;
	closed: string | null;
	status: PaymentStatus;
	methodType: PaymentMethodType;
	totalCoins: number;
	appliedBonusRate: number;
}

export enum CurrencyType {
	NONE = 'NONE',
	RUB = 'RUB',
	USD = 'USD',
	EUR = 'EUR'
}

export interface PaymentMethod {
	type: PaymentMethodType;
	defaultCurrency: CurrencyType;
	currencyList: CurrencyType[];
	minPaymentValue: number;
	optionList: Set<PaymentOptionType>;
}

export enum PaymentStatus {
	Failed = 'Failed',
	Ordered = 'Ordered',
	Succeed = 'Succeed',
	Wait = 'Wait'
}

export enum PaymentMethodType {
	NONE = 'NONE',
	UNITPAY = 'UNITPAY',
	PAYOP = 'PAYOP',
	CRYPTONATOR = 'CRYPTONATOR',
	PAYPAL = 'PAYPAL',
	PAYEER = 'PAYEER',
	INTERKASSA = 'INTERKASSA',
	FREEKASSA = 'FREEKASSA',
	ENOT = 'ENOT',
	PAYPALYCH = 'PAYPALYCH',
	PRIMEPAYMENTS = 'PRIMEPAYMENTS',
	HOTSKINS = 'HOTSKINS'
}

export enum PaymentOptionType {
	VISA = "VISA",
	VISA_MASTER_INTER = "VISA_MASTER_INTER",
	VISA_MASTER_KZ = "VISA_MASTER_KZ",
	BITCOIN = "BITCOIN",
	ETHEREUM = "ETHEREUM",
	LITECOIN = "LITECOIN",
	MASTER = "MASTER",
	THRONE = "THRONE",
}
