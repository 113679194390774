import { HTTP, JsonRpcResponse } from '../lib/http.tsx';

const CONTROLLER = "/account";

export const AccountApi = {
	clientIp: () => { return HTTP.get<string>(CONTROLLER + '/client-ip'); },
	changePassword: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/password/change', { ...data }); },
	allowedIpList: () => { return HTTP.post<UserIp[]>(CONTROLLER + '/allowed-ip/list'); },
	allowedIpAdd: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<UserIp>>(CONTROLLER + '/allowed-ip/add', { ...data }); },
	allowedIpRemove: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<UserIp>>(CONTROLLER + '/allowed-ip/remove', { ...data }); },
	referralInfo: () => { return HTTP.get<ReferralInfoResponse>(CONTROLLER + '/referral/info'); },
	referralEdit: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/referral/edit', { ...data }); }
}

export interface UserIp {
	id: number,
	userId: number,
	ipAddress: string
}

export interface UserReferral {
	name: string,
	date: number
}

export interface ReferralInfoResponse {
	referralId: string,
	referralList: UserReferral[]
}
