import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { JsonRpcResponse } from '../lib/http.tsx';
import { useAppContext } from '../context/app-context.tsx';
import { GameAccountApi, GameAccountLink } from "../api/game-account-api.tsx";
import { IUploadFile, TicketApi } from "../api/ticket-api.tsx";

interface PropsCreateTicket {
	onClose: () => void;
}

const CreateTicketModal = ({ onClose }: PropsCreateTicket) => {
	const { t } = useTranslation();
	const { serverId, accountList } = useAppContext();
	const [isOpenAccounts, setIsOpenAccount] = useState(false);
	const [isOpenPlayers, setIsOpenPlayers] = useState(false);
	const [playerList, setPlayerList] = useState<string[]>([]);

	const [category, setCategory] = useState('');
	const [selectedAccount, setSelectedAccount] = useState<GameAccountLink | null>(null);
	const [characterName, setCharacterName] = useState<string | null>(null);
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [uploadFiles, setUploadFiles] = useState<IUploadFile[]>([]);
	const [files, setFiles] = useState<File[]>([]);

	const toggleOpenAccounts = async () => {
		if (isOpenAccounts) {
			setIsOpenAccount(false);
			return;
		}

		setIsOpenPlayers(false);
		setIsOpenAccount(true);
	}

	const handleSelectAccount = async (account: GameAccountLink) => {
		setSelectedAccount(account);
		setCharacterName(null);
		setIsOpenAccount(false);

		if (serverId !== null) {
			try {
				const response = await GameAccountApi.playerNames({
					serverId: serverId,
					accountLinkId: account.id
				});
				if (response.status === 200) {
					setPlayerList(response.data);
				}
			} catch (error) {

			}
		}
	}

	const toggleOpenPlayers = async () => {
		if (selectedAccount === null || playerList.length <= 0)
			return;

		if (isOpenPlayers) {
			setIsOpenPlayers(false);
			return;
		}

		setIsOpenAccount(false);
		setIsOpenPlayers(true);
	}

	const handleSelectPlayer = (playerName: string) => {
		setCharacterName(playerName);
		setIsOpenPlayers(false);
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;
		if (fileList) {
			const newFiles = Array.from(fileList);
			setFiles((prevFiles) => [...prevFiles, ...newFiles]);
		}
	};

	const handleFileRemove = (index: number) => {
		setUploadFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};

	function validate() {
		let errorMessage = '';
		//if (!category)
		//	errorMessage += '• ' + t("Категория тикета не выбрана!") + "\n";
		if (!subject)
			errorMessage += '• ' + t("Тема тикета не введена!") + '\n';
		if (!message)
			errorMessage += '• ' + t("Описание тикета не введено!") + '\n';
		return errorMessage.trim() || null;
	}

	const handleCreateTicket = async (event: React.FormEvent) => {
		event.preventDefault();

		const errorMsg = validate();
		if (errorMsg !== null) {
			toast.error(errorMsg, { style: { whiteSpace: "pre-line" } });
			return;
		}

		let attachedFiles: string[] = uploadFiles.map(file => file.id);
		try {
			const response = await TicketApi.create({
				category,
				subject,
				message,
				accountId: selectedAccount?.id,
				characterName,
				attachedFiles
			});
			if (response.status !== 200 || !response.data) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data = response.data as JsonRpcResponse<boolean>;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t("errors." + error)).join('\n'));
				return;
			}

			toast("Тикет успешно создан");
			onClose();
		} catch (error) {
			toast.error(t('errors.unexpected_error'));
		}
	};

	const handleAttachFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const files = event.target.files;
		if (!files || files.length === 0) {
			toast.error(t('errors.no_file_selected'));
			return;
		}

		const formData = new FormData();
		formData.append("file", files[0]);
		try {
			const response = await TicketApi.uploadFile(formData);
			if (response.status !== 200 || !response.data) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data = response.data as JsonRpcResponse<IUploadFile>;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t("errors." + error)).join('\n'));
				return;
			}

			setUploadFiles((prev) => [...prev, data.body]);
		} catch (error) {
			toast.error(t('errors.unexpected_error'));
		} finally {
			event.target.value = '';
		}
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		if (droppedFiles.length > 0) {
			const newFiles = Array.from(droppedFiles);
			setFiles((prevFiles) => [...prevFiles, ...newFiles]);
		}
	};

	return (
		<div className="modal-container">
			<div className="bg support"></div>
			<form className="create-ticket" onSubmit={handleCreateTicket}>
				<div className="form-header">
					<p>Создать новый тикет</p>
					<div className="close" onClick={() => onClose()}>
						<img src="/images/icon/close.svg" alt="close" width="24" height="24" />
					</div>
				</div>
				<div className="selector">
					<label htmlFor="category">Категория</label>
					<div className="selector-head">
						<span>{category}</span>
						<img src="/images/icon/drop-arrow.svg" alt="arr" />
					</div>
					<ul className="selector-items">
						{accountList.map((account, index) => (
							<li key={index} className="selector-item">{account.accountName}</li>
						))}
					</ul>
				</div>
				<div className="selectors">
					<div className={`selector ${isOpenAccounts ? 'open' : ''}`}>
						<label htmlFor="account">Ваш аккаунт</label>
						<div className="selector-head" onClick={toggleOpenAccounts}>
							<span>{selectedAccount ? selectedAccount.accountName : "Выбор аккаунта"}</span>
							<img src="/images/icon/drop-arrow.svg" alt="arr" />
						</div>
						<ul className="selector-items">
							{accountList.map((account, index) => (
								<li key={index} className={`selector-item ${selectedAccount === account ? 'active' : ''}`} onClick={() => handleSelectAccount(account)}>
									{account.accountName}
								</li>
							))}
						</ul>
					</div>
					<div className={`selector ${isOpenPlayers ? 'open' : ''}`}>
						<label htmlFor="character">Ваш персонаж</label>
						<div className="selector-head" onClick={toggleOpenPlayers}>
							<span>{characterName || "Выбор персонажа"}</span>
							<img src="/images/icon/drop-arrow.svg" alt="arr" />
						</div>
						<ul className="selector-items">
							{playerList.map((playerName, index) => (
								<li key={index} className={`selector-item ${characterName === playerName ? 'active' : ''}`} onClick={() => handleSelectPlayer(playerName)}>
									{playerName}
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="group-input">
					<label htmlFor="ticket-title">Тема</label>
					<input
						id="ticket-title"
						type="text"
						placeholder="Описание проблемы..."
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
					/>
				</div>
				<div className="group-textarea">
					<label htmlFor="ticket-description">Сообщение</label>
					<textarea
						id="ticket-description"
						placeholder="Описание проблемы..."
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					></textarea>
				</div>
				<div className="file-upload-container" onDrop={handleDrop} onDragOver={(event) => event.preventDefault()}>
					<label htmlFor="file-upload" className="file-upload-header">
						Прикрепить файл (JPG, GIF, PNG)
					</label>
					<div className="file-upload-box">
						<input
							type="file"
							accept=".jpg,.gif,.png"
							id="file-upload"
							style={{ display: "none" }}
							onChange={handleAttachFile}
						/>
						<div className="file-images">
							{uploadFiles.map((file, index) => (
								<div key={index} className="file-image">
									<img src={file.url} alt="" />
									<div className="delete-img" onClick={() => handleFileRemove(index)}>x</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<button type="submit" className="cta">Добавить</button>
			</form>
		</div>
	);
};

export default CreateTicketModal;