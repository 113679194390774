import { CurrencyType, PaymentMethodType, PaymentOptionType, PaymentStatus } from "../api/pay-api.tsx";

export const paymentStatusImages = {
    [PaymentStatus.Failed]: '/images/icon/close.svg',
    [PaymentStatus.Ordered]: '/images/icon/update.svg',
    [PaymentStatus.Succeed]: '/images/icon/check.svg',
    [PaymentStatus.Wait]: '/images/icon/update.svg',
};

export function getImagePathForStatus(status: PaymentStatus): string {
    return paymentStatusImages[status] || '/images/icon/default.png';
}

const paymentMethodImages: Record<PaymentMethodType, string> = {
    [PaymentMethodType.NONE]: '/images/payment/none.png',
    [PaymentMethodType.UNITPAY]: '/images/payment/unitpay.png',
    [PaymentMethodType.PAYOP]: '/images/payment/payop.png',
    [PaymentMethodType.CRYPTONATOR]: '/images/payment/cryptonator.png',
    [PaymentMethodType.PAYPAL]: '/images/payment/paypal.svg',
    [PaymentMethodType.PAYEER]: '/images/payment/payeer.png',
    [PaymentMethodType.INTERKASSA]: '/images/payment/interkassa.png',
    [PaymentMethodType.FREEKASSA]: '/images/payment/freekassa.png',
    [PaymentMethodType.ENOT]: '/images/payment/enot.png',
    [PaymentMethodType.PAYPALYCH]: '/images/payment/paypalych.png',
    [PaymentMethodType.PRIMEPAYMENTS]: '/images/payment/primepayments.svg',
    [PaymentMethodType.HOTSKINS]: '/images/payment/hotskins.png',
};

export function getImagePathForMethodType(methodType: PaymentMethodType): string {
    return paymentMethodImages[methodType] || '/images/payment/default.png';
}

const paymentOptionImages: Record<PaymentOptionType, string> = {
	[PaymentOptionType.VISA]: "/images/payment/option/visa.svg",
	[PaymentOptionType.VISA_MASTER_INTER]: "/images/payment/option/visa-master-inter.svg",
	[PaymentOptionType.VISA_MASTER_KZ]: "/images/payment/option/visa-master-kz.svg",
	[PaymentOptionType.BITCOIN]: "/images/payment/option/bitcoin.svg",
	[PaymentOptionType.ETHEREUM]: "/images/payment/option/ethereum.svg",
	[PaymentOptionType.LITECOIN]: "/images/payment/option/lightcoin.svg",
	[PaymentOptionType.MASTER]: "/images/payment/option/master.svg",
	[PaymentOptionType.THRONE]: "/images/payment/option/throne.svg",
};

export function getPaymentMethodIcon(type: PaymentOptionType): string {
    return paymentOptionImages[type] || "";
};

const currencySymbols: Record<CurrencyType, string> = {
	[CurrencyType.NONE]: "💳",
	[CurrencyType.RUB]: "₽",
	[CurrencyType.USD]: "$",
	[CurrencyType.EUR]: "€"
  };

export function getCurrencySymbol(type: CurrencyType): string {
    return currencySymbols[type] || "";
};