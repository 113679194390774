import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import config from './../../config.tsx';
import { JsonRpcResponse } from '../../lib/http.tsx';
import { AccountApi, ReferralInfoResponse, UserReferral } from '../../api/account-api.tsx';

function SettingReferral() {
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [referralId, setReferralId] = useState<string>('');
	const [newReferralId, setNewReferralId] = useState<string>('');
	const [referralUsers, setReferralUsers] = useState<UserReferral[]>([]);

	const referralUrl = `${config.BASE_URL}/register?ref=${referralId}`;

	const updateReferralInfo = async () => {
		try {
			const response = await AccountApi.referralInfo();
			if (response.status === 200) {
				const data: ReferralInfoResponse = response.data;
				setReferralId(data.referralId);
				setReferralUsers(data.referralList);
			}
		} catch (error) {

		}
	}

	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(referralUrl);
			toast.success("Referral code copied to clipboard!");
		} catch (error) {
			console.error("Failed to copy text: ", error);
		}
	};

	function validate() {
		let errorMessage = '';
		if (newReferralId.trim() === '')
			errorMessage += t("Реферальный код не может быть пустым!") + '\n';
		else if (referralId.trim() === newReferralId.trim())
			errorMessage += t("Реферальный код совпадает с текущим!") + '\n';
		else if (newReferralId.trim().length < 3 || newReferralId.trim().length > 16)
			errorMessage += t("referral.invalid_length") + '\n';
		return errorMessage.trim() || null;
	}

	const handleUpdateReferralId = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const errorMsg = validate();
		if (errorMsg !== null) {
			toast.error(errorMsg, { style: { whiteSpace: "pre-line" } });
			return;
		}

		try {
			const response = await AccountApi.referralEdit({code: newReferralId});
			if (response.status !== 200) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data: JsonRpcResponse<boolean> = response.data;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(error)).join('\n'));
				return;
			}

			setReferralId(newReferralId);
			setNewReferralId('');
			toast("Код приглашения обновлен!");
			setIsModalOpen(false);
		} catch (error) {
			toast.error(t('errors.unexpected_error'));
		}
	};

	const handleCloseModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		setNewReferralId('');
		setIsModalOpen(false);
	}

	useEffect(() => {
		updateReferralInfo();
	}, []);

	return (
		<>
			{isModalOpen && (
				<div className="modal-container">
					<form className="form" onSubmit={handleUpdateReferralId}>
						<div className="form-header">
							<p>Изменить реферальный код</p>
							<div className="close" onClick={handleCloseModal}>
								<img src="/images/icon/close.svg" alt="close" width="24" height="24" />
							</div>
						</div>
						<div className="form-group login">
							<label htmlFor="register-username">Реферальный код</label>
							<input value={newReferralId} onChange={(e) => setNewReferralId(e.target.value)} type="text" id="register-username" placeholder="Введите ваш код" />
						</div>
						<button type="submit" className="cta-secondary"><span>Изменить</span></button>
						<div className="annotation">
							<p>
								Код может содержать только английские буквы и цифры, и его длина
								должна составлять не менее 3-х и не более 16-ти символов.
							</p>
						</div>
					</form>
				</div>
			)}
			<div className="settings-container border-gradient">
				<div className="form-header">
					<p>{t('referral.header')}</p>
				</div>
				<div className="setting-left">
					<form action="">
						<div className="referal-group">
							<label htmlFor="ip" className="referal-label">
								{t('referral.code_label')}
								<span className="change-link" onClick={() => setIsModalOpen(true)}>
									<img src="/images/icon/pen.svg" alt="pen" />
								</span>
							</label>
							<input type="text" className="input-secondary" id="ip" value={referralUrl} readOnly />
							<div className="copy-link" onClick={handleCopy}>
								<img src="/images/icon/copy.svg" alt="copy" />
							</div>
						</div>
					</form>
					<div className="table-list">
						<div className="table-head">
							<p>{t('referral.invited')}</p>
							<p>{t('referral.date')}</p>
						</div>
						{referralUsers.map((referral, index) => (
							<div key={index} className="table-row-secondary">
								<p>{index+1}</p>
								<p>{referral.created}</p>
								<div className="delete">
									<img src="/images/icon/close.svg" alt="del" width="16" height="16" />
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="setting-right">
					<div className="border-gradient">
						<h2>{t('referral.invite_friends_header')}</h2>
						<p>{t('referral.invited_users_description')}</p>
						<p>{t('referral.referral_bonus_description')}</p>
						<div className="img-block">
							<img src="/images/icon/fish.svg" alt="phone" width="45" height="45" />
							<p>{t('referral.program_limits_header')}</p>
						</div>
						<div className="settings-list">
							<div className="setting-item">
								<div className="label">{t('referral.bonus_rate_label')}</div>
								<div className="dots"></div>
								<strong className="value">0.05</strong>
							</div>
							<div className="setting-item">
								<div className="label">{t('referral.bonus_limit_label')}</div>
								<div className="dots"></div>
								<strong className="value">1000</strong>
							</div>
							<div className="setting-item">
								<div className="label">{t('referral.bonus_max_per_referral_label')}</div>
								<div className="dots"></div>
								<strong className="value">5</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SettingReferral;