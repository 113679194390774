import React, { useState } from 'react';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AsideBalance from './components/aside/balance.tsx';
import AsidePromocode from './components/aside/promocode.tsx';
import AsideServer from './components/aside/server.tsx';
import SnowfallCanvas from './effects/SnowfallCanvas.tsx';
import AsideWarehouse from './components/aside/warehouse.tsx';
import AsideMarket from './components/aside/market.tsx';

function App() {
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const [redirect, setRedirect] = useState(false);
	const [showEffect, setShowEffect] = useState(false);

	const changeLanguage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru');
	};

	const handleLogOut = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		localStorage.clear();
		setRedirect(true);
	}

	const handleOpenSetting = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
		event.preventDefault();
		navigate('/setting', {
			state: { index }
		});
	}

	if (redirect) {
		return <Navigate to="/login" />;
	}

	return (
		<>
			{showEffect && <>
				<div className="bg newyear"></div>
				<SnowfallCanvas />
			</>}
			<ToastContainer position="top-right" style={{ fontSize: '14px' }} limit={5} />
			<div className="container">
				<header>
					<Link to="#" className="logo" onClick={() => setShowEffect(!showEffect)}>
						<img src="/images/logo.svg" alt="Genesis Lineage 2" />
						<span>genesis world</span>
					</Link>
					<ul className="menu">
						<li className="menu-item">
							<Link to="/" className="menu-link">
								<img src="/images/icon/home.svg" alt="home" width="16" height="16" />
								{t('menu.home')}
							</Link>
						</li>
						<li className="menu-item">
							<Link to="/shop" className="menu-link">
								<img src="/images/icon/drop-menu.svg" alt="shop" width="16" height="16" />
								{t('menu.shop')}
							</Link>
						</li>
						<li className="menu-item">
							<Link to="/setting" onClick={(e) => handleOpenSetting(e, 0)} className="menu-link">
								<img src="/images/icon/settings.svg" alt="settings" width="16" height="16" />
								{t('menu.settings')}
							</Link>
						</li>
						<li className="menu-item">
							<Link to="/setting" onClick={(e) => handleOpenSetting(e, 2)} className="menu-link">
								<img src="/images/icon/referal.svg" alt="referrals" width="16" height="16" />
								{t('menu.referrals')}
							</Link>
						</li>
						<li className="menu-item">
							<Link to="/support" className="menu-link">
								<img src="/images/icon/support.svg" alt="support" width="16" height="16" />
								{t('menu.support')}
							</Link>
						</li>
					</ul>
					<Link to="#" onClick={changeLanguage} className="language">{i18n.language === 'ru' ? 'RU' : 'EN'}</Link>
					<div className="user-account">
						<img src="/images/icon/account.svg" alt={t("profile.alt")} />
						<span className="user-name">{t("profile.profile")}</span>
						<img src="/images/icon/drop-arrow.svg" alt={t("profile.arrow_alt")} className="drop-arrow" />
						<ul className="submenu">
							<li className="submenu-item">
								<Link to="/donation" className="submenu-link">
									<img src="/images/icon/donation-solid.svg" alt="" width="18" height="18" />
									{t("profile.balance")}
								</Link>
							</li>
							<li className="submenu-item">
								<Link to="/donation-history" className="submenu-link">
									<img src="/images/icon/calendar-accent.svg" alt="" width="18" height="18" />
									{t("profile.donation_history")}
								</Link>
							</li>
							<li className="submenu-item">
								<Link to="/shop-history" className="submenu-link">
									<img src="/images/icon/calendar-accent.svg" alt="" width="18" height="18" />
									{t("profile.shop_history")}
								</Link>
							</li>
							<li className="submenu-item">
								<Link to="#" onClick={handleLogOut} className="submenu-link">
									<img src="/images/icon/logout.svg" alt="" width="18" height="18" />
									{t("profile.logout")}
								</Link>
							</li>
						</ul>
					</div>
				</header>
				<main>
					<aside className="user-dashboard">
						<div className="server-promo">
							<AsideServer />
							<AsidePromocode />
						</div>
						<AsideBalance />
						{false && <AsideWarehouse />}
						{false && <AsideMarket />}
					</aside>
					<Outlet />
				</main>
			</div>
		</>
	);
}

export default App;