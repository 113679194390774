import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GameAccount from '../components/game-account.tsx';
import { Link, Outlet } from 'react-router-dom';

function Accounts() {
	const { t } = useTranslation();
	const [offerItemList, setOfferItemList] = useState<any[]>([]);

	return (
		<>
			<div className="bg home"></div>
			<GameAccount />
			<div className="dark-container">
				<div className="container-head">
					<p className="title">{t('support.title')}</p>
					<Link className="cta" to="/support">
						<img src="/images/icon/pen.svg" alt="pen" />
						{t('support.create_ticket')}
					</Link>
				</div>
				<p>{t('support.section_description')}</p>
				<p>{t('support.help_description')}</p>
				<div className="container-head">
					<p className="title">{t('support.products')}</p>
				</div>
				<div className="offers">
					{offerItemList.map((item, index) => (
						<div key={index} className="offer-item">
							<div className="offer-img">
								<img src="/images/item.webp" alt="" />
							</div>
							<div className="offer-info">
								<div className="offer-head">
									<p className="offer-name">
										Премиум подписка <br />
										<span className="accent">1 месяц</span>
									</p>
									<div className="offer-price coins">
										<img src="/images/icon/coin.svg" alt="" />
										200
									</div>
								</div>
								<p className="offer-description">
									После активации увеличивает рейты для всех персонажей аккаунта
									в течение 30 дней
								</p>
								<div className="cta">Купить</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<Outlet />
		</>
	);
}

export default Accounts;