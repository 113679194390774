import React, { useEffect, useState } from 'react';
import { ITicket, TicketApi } from '../api/ticket-api.tsx';
import CreateTicketModal from '../components/create-ticket-modal.tsx';

function Support() {
	const [themeList, setThemeList] = useState<ITicket[]>([]);
	const [isModalMessage, setIsModalMessage] = useState<boolean>(false);
	const [isModalCreate, setIsModalCreate] = useState<boolean>(false);

	async function updateThreadList() {
		try {
			const response = await TicketApi.threadList();
			if (response.status === 200) {
				setThemeList(response.data);
			}
		} catch (error) {

		}
	}

	useEffect(() => {
		updateThreadList();
	}, []);

	return (
		<>
			<div className="bg support"></div>
			<div className="dark-container">
				<div className="container-head">
					<p className="title">Служба поддержки</p>
					<div className="cta" onClick={() => setIsModalCreate(true)}>
						<img src="/images/icon/pen.svg" alt="pen" width="16" height="16" />
						Создать тикет
					</div>
				</div>
				<div className="table-container support">
					<div className="table-header">
						<p className="col id">ID</p>
						<p className="col topick">Тема</p>
						<p className="col nick"><span>Никнейм</span></p>
						<p className="col date">Дата</p>
						<p className="col date">Обновлено</p>
						<p className="col status">Статус</p>
						<p className="col action">Действие</p>
					</div>
					<div className="table-body">
						{themeList.map((theme, index) => (
							<div key={index} className="table-row">
								<p className="col id">{theme.id}</p>
								<p className="col topick"><span>{theme.title}</span></p>
								<p className="col nick"><span>{theme.characterName || '-'}</span></p>
								<p className="col date">10.07.2024 <time dateTime="">15:35:47</time></p>
								<p className="col date">10.07.2024 <time dateTime="">15:35:47</time></p>
								<p className="col status">{theme.status}</p>
								<p className="col action">
									<span className="icon chat">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M12.8693 19.5102L13.303 18.7775C13.6394 18.2092 13.8076 17.925 14.0777 17.7679C14.3479 17.6107 14.688 17.6049 15.3683 17.5932C16.3726 17.5759 17.0025 17.5143 17.5307 17.2955C18.5108 16.8895 19.2895 16.1108 19.6955 15.1307C20 14.3956 20 13.4638 20 11.6V10.8C20 8.18126 20 6.87188 19.4106 5.91001C19.0807 5.37178 18.6282 4.91926 18.09 4.58944C17.1281 4 15.8187 4 13.2 4H10.8C8.18126 4 6.87188 4 5.91001 4.58944C5.37178 4.91926 4.91926 5.37178 4.58944 5.91001C4 6.87188 4 8.18126 4 10.8V11.6C4 13.4638 4 14.3956 4.30448 15.1307C4.71046 16.1108 5.48915 16.8895 6.46927 17.2955C6.99751 17.5143 7.62737 17.5759 8.63166 17.5932C9.31194 17.6049 9.65207 17.6107 9.92223 17.7679C10.1924 17.925 10.3606 18.2092 10.697 18.7775L11.1306 19.5102C11.5172 20.1632 12.4828 20.1632 12.8693 19.5102ZM15.2 12C15.6418 12 16 11.6418 16 11.2C16 10.7582 15.6418 10.4 15.2 10.4C14.7582 10.4 14.4 10.7582 14.4 11.2C14.4 11.6418 14.7582 12 15.2 12ZM12.8 11.2C12.8 11.6418 12.4418 12 12 12C11.5582 12 11.2 11.6418 11.2 11.2C11.2 10.7582 11.5582 10.4 12 10.4C12.4418 10.4 12.8 10.7582 12.8 11.2ZM8.8 12C9.24183 12 9.6 11.6418 9.6 11.2C9.6 10.7582 9.24183 10.4 8.8 10.4C8.35817 10.4 8 10.7582 8 11.2C8 11.6418 8.35817 12 8.8 12Z"
												fill="#C0C3CD"
											/>
										</svg>
									</span>
								</p>
							</div>
						))}
					</div>
				</div>
			</div>

			{isModalMessage && (
				<div className="modal-container">
					<div className="bg support"></div>
					<form className="create-ticket chat">
						<div className="form-header">
							<p>Точка скилов <span>Заявка: № 787897</span></p>
							<div className="close" onClick={() => setIsModalMessage(false)}>
								<img src="/images/icon/close.svg" alt="close" width="24" height="24" />
							</div>
						</div>
						<div className="chat-container">
							<div className="user-message">
								<div className="message">
									<p>test message</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
										rem sunt quod, molestias maiores atque, quae asperiores corrupti
										labore recusandae incidunt optio deserunt temporibus nostrum
										repellendus dolorum necessitatibus voluptates maxime?
									</p>
								</div>
								<div className="readed">
									<img src="/images/icon/readed.svg" alt="" />
									<time dateTime="">09.15.2024 15:51:12</time>
								</div>
							</div>
							<div className="admin-message">
								<div className="message">
									<p>test message</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
										rem sunt quod, molestias maiores atque, quae asperiores corrupti
										labore recusandae incidunt optio deserunt temporibus nostrum
										repellendus dolorum necessitatibus voluptates maxime?
									</p>
								</div>
								<div className="readed">
									<img src="/images/icon/unreaded.svg" alt="" />
									<time dateTime="">09.15.2024 15:51:12</time>
								</div>
							</div>
							<div className="admin-message">
								<div className="message">
									<p>test message</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
										rem sunt quod, molestias maiores atque, quae asperiores corrupti
										labore recusandae incidunt optio deserunt temporibus nostrum
										repellendus dolorum necessitatibus voluptates maxime?
									</p>
								</div>
								<div className="readed">
									<img src="/images/icon/unreaded.svg" alt="" />
									<time dateTime="">09.15.2024 15:51:12</time>
								</div>
							</div>
						</div>
						<div className="group-textarea chat">
							<div className="chat-messanger">
								<textarea
									name="ticket-description"
									id="ticket-description"
									placeholder="Написать сообщение ...">
								</textarea>
								<div className="ctas">
									<div className="file-attached">
										<img src="/images/icon/attach-file.svg" alt="" />
									</div>
									<button className="send-message">
										<img src="/images/icon/send-message-tg.svg" alt="" />
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			)}
			{isModalCreate && <CreateTicketModal onClose={() => setIsModalCreate(false)} />}
		</>
	);
}

export default Support;