import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import SignInForm from '../../components/login/sign-in.tsx';
import SignUpForm from '../../components/login/sign-up.tsx';

const AuthPage = ({ initialTab }) => {
	const { t } = useTranslation();
	const [activeForm, setActiveForm] = useState<string>('login');

	useEffect(() => {
		setActiveForm(initialTab);
	}, [initialTab]);

	return (
		<>
			<div className="bg login"></div>
			<ToastContainer style={{ fontSize: '14px' }} />
			<div className="login">
				<div className="logo">
					<img src="/images/logo.svg" alt="Genesis Lineage 2" />
					<span>{t('page.login.title')}</span>
				</div>
				<div className="login-container">
					<div className="tabs">
						<div className={`tab ${activeForm === 'login' ? 'active' : ''}`} onClick={() => setActiveForm('login')}>
							{t('page.login.title')}
						</div>
						<div className={`tab ${activeForm === 'register' ? 'active' : ''}`} onClick={() => setActiveForm('register')}>
							{t('page.login.register_title')}
						</div>
					</div>
					<SignInForm activeForm={activeForm} />
					<SignUpForm activeForm={activeForm} />
					<div className="link-group">
						<Link to="/password-reset">{t('page.login.password_reset')}</Link>
						<Link to="#">{t('page.login.twofa_reset')}</Link>
						<Link to="/confirm-request-mail">{t('page.login.confirm_email')}</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default AuthPage;