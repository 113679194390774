import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PayApi } from '../../api/pay-api.tsx';

function AsideBalance() {
	const { t } = useTranslation();
	const [balance, setBalance] = useState(0);

	async function updateBalance() {
		try {
			const response = await PayApi.balance();
			if (response.status === 200) {
				setBalance(response.data);
			}
		} catch (error) {

		}
	}

	useEffect(() => {
		updateBalance();
	}, []);

	return (
		<div className="balance-aside aside-container">
			<p className="name">
				<img src="/images/icon/balance.svg" alt="diamond" width="20" height="20" />
				{t('aside.balance.title')}
			</p>
			<p className="quantity">
				<img src="/images/icon/coin.svg" alt="coin" />
				<span className="number">{balance}</span>
			</p>
			<Link to="/donation" className="cta">{t('aside.balance.top_up_balance')}</Link>
			<div className="update-data" onClick={updateBalance}>
				<img src="/images/icon/update.svg" alt="update" width="16" height="16" />
				{t('aside.balance.update')}
			</div>
		</div>
	);
}

export default AsideBalance;