import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingPassword from '../components/setting/setting-password.tsx';
import SettingIp from '../components/setting/setting-ip.tsx';
import SettingReferral from '../components/setting/setting-referal.tsx';

function Setting() {
	const location = useLocation();
	const { t } = useTranslation();
	const { index } = location.state || { index: 0 };

	const [activeTab, setActiveTab] = useState(0);

	const tabTitles = [
		t("setting.tab.password_change"),
		t("setting.tab.ip_limit"),
		//t("setting.tab.authentication"),
		t("setting.tab.referrals"),
	];

	const tabContent = [
		<SettingPassword />,
		<SettingIp />,
		//<Setting2Fa />,
		<SettingReferral />
	];

	useEffect(() => {
		setActiveTab(index);
	}, [location]);

	function handleSetTab(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number): void {
		event.preventDefault();
		setActiveTab(index);
	}

	return (
		<>
			<div className="bg settings"></div>
			<div className="dark-container">
				<div className="container-head"><p className="title">{t("setting.setting")}</p></div>
				<div className="settings-tabs">
					{tabTitles.map((title, index) => (
						<Link
							to="#"
							key={index}
							className={`setting-tab ${activeTab === index ? "active" : ""}`}
							onClick={(e) => handleSetTab(e, index)}
						>
							<span>{title}</span>
						</Link>
					))}
				</div>
				<div className="tab-content">
					{tabContent[activeTab]}
				</div>
			</div>
		</>
	);
}

export default Setting;