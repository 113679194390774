import { HTTP, JsonRpcResponse } from '../lib/http.tsx';

const CONTROLLER = "/ticket";

export const TicketApi = {
	threadList: () => { return HTTP.get<ITicket[]>(CONTROLLER + '/tickets'); },
	create: <T,>(data: T) => { return HTTP.post<JsonRpcResponse<boolean>>(CONTROLLER + '/create', { ...data }); },
	uploadFile: (formData: FormData) => {
		return HTTP.post<JsonRpcResponse<IUploadFile>>(CONTROLLER + '/upload-file', formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}
}

export interface ITicket {
	id: number;
	category: string;
	subject: string;
	message: string;
	status: string;
	accountId: string;
	characterName: string;
}

export interface IUploadFile {
	id: string;
	url: string;
	date: string;
}