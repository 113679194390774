import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import config from "../../config.tsx";
import { JsonRpcResponse } from "../../lib/http.tsx";
import { AuthApi, IConfirmMailResponse } from '../../api/auth-api.tsx';

function EmailConfirmationPage() {
	const { token } = useParams<{ token?: string }>();
	const { t } = useTranslation();
	const [confirmData, setConfirmData] = useState<IConfirmMailResponse | null>(null);

	const labels = [
		t('password-file.account_prefix'),
		t('password-file.login'),
		t('password-file.email'),
		t('password-file.password')
	];

	const maxLabelLength = Math.max(...labels.map(label => label.length)) + 3;

	const confirmEmail = async () => {
		try {
			const response = await AuthApi.confirmEmail({ token });
			if (response.status === 200 && response.data.status !== 'error') {
				const data = response.data as JsonRpcResponse<IConfirmMailResponse>;
				setConfirmData(data.body);
			}
		} catch (error) {

		}
	};

	const getAlignedText = (label, value, maxLabelLength) => {
		const spacesNeeded = Math.max(0, maxLabelLength - label.length);
		const spaces = ' '.repeat(spacesNeeded);
		return `${label}${spaces}${value}`;
	};

	const handleDownload = () => {
		if (confirmData === null)
			return;

		const data =
			`${t('password-file.title')} ${config.BASE_URL}\n` +
			`${t('password-file.authorization_data')}\n\n` +
			//getAlignedText(t('password-file.account_prefix'), textData.accountPrefix, maxLabelLength) + '\n' +
			//getAlignedText(t('password-file.login'), confirmData.login, maxLabelLength) + '\n' +
			getAlignedText(t('password-file.email'), confirmData.email, maxLabelLength) + '\n' +
			getAlignedText(t('password-file.password'), confirmData.password, maxLabelLength) + '\n\n' +
			`${t('password-file.store_message')}`;

		const blob = new Blob([data], { type: 'text/plain' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = 'password.txt';
		link.click();
	};

	useEffect(() => {
		if (token != null)
			confirmEmail();
	}, [token]);

	if (!confirmData) {
		return (
			<div className="notification-full">
				<div className="notification-content">
					<h2 className="accent">Активация аккаунта</h2>
					<p>Регистрационный код не найден или был ранее активирован!</p>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="notification-full">
				<div className="notification-content">
					<h2 className="accent">Ожидание активации аккаунта</h2>
					<p>
						Регистрация успешно завершена
					</p>
					<button onClick={handleDownload}>Сохранить в .txt файл.</button>
					<div className="link-group">
						<Link to="/login">Вход</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default EmailConfirmationPage;