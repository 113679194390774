import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { JsonRpcResponse } from '../../lib/http.tsx';
import { AccountApi, UserIp } from '../../api/account-api.tsx';
import { Link } from 'react-router-dom';

function SettingIp() {
	const { t } = useTranslation();
	const [ipList, setIpList] = useState<UserIp[]>([]);
	const [ip, setIp] = useState<string>('');

	const updateIpList = async () => {
		try {
			const response = await AccountApi.allowedIpList();
			if (response.status === 200) {
				setIpList(response.data.sort());
			}
		} catch (error) {

		}
	}

	const handleAddIp = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		if (ip.trim() === '') {
			toast.error(t('errors.ip_not_entered'));
			return;
		}

		try {
			const response = await AccountApi.allowedIpAdd({ ip });
			if (response.status !== 200) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data = response.data as JsonRpcResponse<UserIp>;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(error)).join('\n'));
				return;
			}

			setIp('');
			setIpList(prevIpList => [...prevIpList, data.body]);
			toast(t('message.ip_added_successfully'));
		} catch (error) {
			toast.error(t('errors.unexpected_error'));
		}
	}

	const handleRemoveIp = async (event: React.MouseEvent<HTMLParagraphElement>, ip: string) => {
		event.preventDefault();
		try {
			const response = await AccountApi.allowedIpRemove({ ip });
			if (response.status !== 200) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data = response.data as JsonRpcResponse<UserIp>;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(error)).join('\n'));
				return;
			}

			setIpList(prevIpList => prevIpList.filter(userIp => userIp.ipAddress !== ip));
			toast(t('message.ip_removed_successfully'));
		} catch (error) {
			toast.error(t('errors.unexpected_error'));
		}
	}

	const handleCurrentIp = async (event: React.MouseEvent<HTMLParagraphElement>) => {
		event.preventDefault();
		try {
			const response = await AccountApi.clientIp();
			if (response.status === 200 && response.data) {
				setIp(response.data);
			} else {
				toast.error(t('errors.server_request_failed'));
			}
		} catch (error) {
			toast.error(t('errors.unexpected_error'));
		}
	}

	useEffect(() => {
		updateIpList();
	}, []);

	return (
		<>
			<div className="settings-container border-gradient">
				<div className="form-header"><p>{t('setting.ip-limits.header')}</p></div>
				<div className="setting-left">
					<form action="">
						<label htmlFor="ip">{t('setting.ip-limits.add_ip')}</label>
						<div className="ctas">
							<input
								type="text"
								style={{ flex: 1 }}
								value={ip}
								onChange={(e) => setIp(e.target.value)}
								className="input-secondary"
								placeholder="IPv4 or IPv6 address"
								id="ip"
							/>
							<button onClick={handleAddIp} className="cta">{t('setting.ip-limits.add_ip')}</button>
						</div>
						<p className="current-ip" onClick={handleCurrentIp}>
							<img src="/images/icon/wifi.svg" alt="wifi" width="16" height="16" />
							{t('setting.ip-limits.current_ip')}
						</p>
					</form>
					<div className="table-list">
						<div className="table-head">
							<p>{t('setting.ip-limits.added_ips')}</p>
						</div>
						{ipList.map((ip, index) => (
							<div key={index} className="table-row-secondary">
								<p>{ip.ipAddress}</p>
								<div className="delete" onClick={(e) => handleRemoveIp(e, ip.ipAddress)}>
									<img src="/images/icon/close.svg" alt="del" width="16" height="16" />
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="setting-right">
					<div className="border-gradient">
						<h2>{t('setting.ip-limits.ip_warning')}</h2>
						<p>{t('setting.ip-limits.ip_warning_desc')}</p>
						<div className="img-block">
							<img src="/images/icon/web.svg" alt="phone" width="45" height="45" />
							<div className="content">
								<p className="title">{t('setting.ip-limits.static_ip')}</p>
								<p>{t('setting.ip-limits.dynamic_ip_warning')}</p>
							</div>
						</div>
						<div className="img-block">
							<p>{t('setting.ip-limits.remove_ip_desc')}</p>
						</div>
						<p>{t('setting.ip-limits.support_contact')}</p>
						<Link className="cta" to="/support">
							<img src="/images/icon/pen.svg" alt="pen" />
							{t('setting.ip-limits.create_ticket')}
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default SettingIp;