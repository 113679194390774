import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IAuthResponse } from '../api/auth-api';

import config from '../config.tsx';

import App from '../App.tsx';
import AuthPage from '../pages/auth/login.tsx';
import EmailConfirmationPage from '../pages/auth/confirm-result-mail.tsx';

import Accounts from '../pages/index.tsx';
import Support from '../pages/support.tsx';
import Donation from '../pages/donate.tsx';
import Setting from '../pages/setting.tsx';
import Shop from '../pages/shop.tsx';
import DonationHistory from '../pages/donate-history.tsx';
import ConfirmEmail from '../pages/auth/confirm-mail.tsx';
import ResendMailConfirmation from '../pages/auth/confirm-request-mail.tsx';
import PasswordRecovery from '../pages/auth/password-reset.tsx';
import PasswordConfirmationPage from '../pages/auth/password-reset-complate.tsx';
import GameResetPin from '../pages/email-actions/game-reset-pin.tsx';
import GameResetLockPin from '../pages/email-actions/game-reset-lock-pin.tsx';
import GameChangePassword from '../pages/email-actions/game-change-password.tsx';

const AuthRoute = (props) => {
	const payload: IAuthResponse = JSON.parse(localStorage.getItem('user') || "{}");
	const token = payload.accessToken || "";

	const isLoggedIn = token.length !== 0;
	if (!isLoggedIn && props.needAuth)
		return <Navigate to="/login" replace />;

	if (isLoggedIn && !props.needAuth)
		return <Navigate to="/" replace />;

	return <Outlet />;
};

const withReCaptcha = (element: React.JSX.Element) => {
	if (config.ENABLE_RECAPTCHA && config.RE_CAPTCHA_KEY) {
		return (
			<GoogleReCaptchaProvider reCaptchaKey={config.RE_CAPTCHA_KEY}>
				{element}
			</GoogleReCaptchaProvider>
		);
	}
	
	return element;
};

const AppRoute = [
	{
		element: <AuthRoute needAuth={false} />,
		children: [
			{ path: 'login', element: withReCaptcha(<AuthPage initialTab="login" />) },
			{ path: 'register', element: withReCaptcha(<AuthPage initialTab="register" />) },
			{ path: 'activation', element: <ConfirmEmail /> },
			{ path: 'confirm-request-mail', element: withReCaptcha(<ResendMailConfirmation />) },
			{ path: 'confirm-email/:token', element: <EmailConfirmationPage /> },
			{ path: 'password-reset', element: withReCaptcha(<PasswordRecovery />) },
			{ path: 'password-reset/:token', element: <PasswordConfirmationPage /> },
		]
	},
	{
		element: <AuthRoute needAuth={true} />,
		children: [
			{
				element: <App />,
				children: [
					{
						path: '/',
						element: <Accounts />,
						children: [
							{ path: 'reset-pin/:code', element: <GameResetPin /> },
							{ path: 'reset-lock/:code', element: <GameResetLockPin /> },
							{ path: 'change-password/:account/:token', element: <GameChangePassword /> }
						]
					},
					{ path: '/setting', element: <Setting /> },
					{ path: '/donation', element: <Donation /> },
					{ path: '/donation-history', element: <DonationHistory /> },
					{ path: '/shop', element: <Shop /> },
					{ path: '/support', element: <Support /> },
					{ path: '*', element: <Navigate to="/" /> }
				]
			}
		]
	}
];

export default AppRoute;