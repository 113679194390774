import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { JsonRpcResponse } from '../lib/http.tsx';
import { CurrencyType, PayApi, PaymentMethod, PaymentMethodType, PaymentOptionType } from '../api/pay-api.tsx';
import { getImagePathForMethodType, getPaymentMethodIcon } from '../utils/payment-util.tsx';

interface ValueBonus {
	value: number;
	bonus: number;
}

interface OptionWithMethods {
	option: PaymentOptionType;
	paymentMethods: PaymentMethod[];
}

const generateOptionsList = (paymentMethods: PaymentMethod[]): OptionWithMethods[] => {
	const optionsList: OptionWithMethods[] = [];
	paymentMethods.forEach((method) => {
		method.optionList.forEach((option) => {
			const existingOption = optionsList.find((item) => item.option === option);
			if (existingOption) {
				existingOption.paymentMethods.push(method);
			} else {
				optionsList.push({ option, paymentMethods: [method] });
			}
		});
	});

	return optionsList;
};

function Donation() {
	const basePriceRub = 1;
	const { t } = useTranslation();
	const [optionsList, setOptionsList] = useState<OptionWithMethods[]>([]);
	const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
	const [selectedMethodIndex, setSelectedMethodIndex] = useState(-1);
	const [prices, setPrices] = useState({ rub: basePriceRub, usd: 0, uah: 0, eur: 0 });
	const [amount, setBalance] = useState(1);
	const [currency, setCurrency] = useState('NONE');
	const [method, setMethod] = useState('NONE');
	const [bonus, setBonus] = useState(0);

	const updatePaymentSystem = async () => {
		try {
			const response = await PayApi.paymentSystems();
			if (response.status === 200) {
				const data = response.data as PaymentMethod[];
				setOptionsList(generateOptionsList(data));
			}
		} catch (error) {

		}
	}

	const exchangeRates = {
		usd: 0.010,
		uah: 0.42,
		eur: 0.0095
	};

	const calculatePrices = () => {
		const totalPriceRub = basePriceRub * amount;
		setPrices({
			rub: totalPriceRub,
			usd: (totalPriceRub * exchangeRates.usd),
			uah: (totalPriceRub * exchangeRates.uah),
			eur: (totalPriceRub * exchangeRates.eur)
		});
	};

	const ranges = [
		{ min: 1, bonusPercentage: 1.0 },
		{ min: 3000, bonusPercentage: 1.11 },
		{ min: 5000, bonusPercentage: 1.12 },
		{ min: 10000, bonusPercentage: 1.14 },
		{ min: 12000, bonusPercentage: 1.15 },
	];

	const calculateBonus = (value: number) => {
		for (let i = 0; i < ranges.length; i++) {
			const step = ranges[i];
			const nextMax = i < ranges.length - 1 ? ranges[i + 1].min - 1 : Infinity;
			if (value >= step.min && value <= nextMax) {
				return value * step.bonusPercentage;
			}
		}

		return 0;
	};

	const generateValuesAndBonuses = (N: number, min: number, max: number) => {
		const valuesAndBonuses: ValueBonus[] = [];
		const step = (max - min) / (N - 1);
		for (let i = 0; i < N; i++) {
			const value = min + i * step;
			const bonus = calculateBonus(value) - value;
			valuesAndBonuses.push({ value: Math.round(value), bonus: Math.round(bonus) });
		}

		return valuesAndBonuses;
	};

	const N = 5;
	const min = 1;
	const max = 200000;
	const valuesAndBonuses = generateValuesAndBonuses(N, min, max);

	const updateSlider = (value: number) => {
		setBonus(calculateBonus(value) - amount);
	};

	const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setBalance(event.target.valueAsNumber);
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = parseInt(event.target.value);
		if (isNaN(value)) value = 1;
		if (value < 1) value = 1;
		if (value > 200000) value = 200000;
		setBalance(value);
	};

	const getLabelClass = (stepMax: number) => {
		return amount >= stepMax ? 'highlighted' : '';
	};

	const handleSetPaymentOption = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		event.preventDefault();
		setSelectedOptionIndex(index);
		setSelectedMethodIndex(-1);
		setMethod('NONE');
	}

	const handleSetPaymentMethod = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, option: PaymentMethod) => {
		event.preventDefault();
		setSelectedMethodIndex(index);
		setMethod(option.type);
		setCurrency(option.defaultCurrency);
	}

	function validate() {
		let errorMessage = '';
		if (amount <= 0 || amount < min)
			errorMessage += '• ' + t("payment.too_small") + "\n";
		if (currency == null || currency == CurrencyType.NONE)
			errorMessage += '• ' + t("payment.currency_not_selected") + '\n';
		else if (method == null || method == PaymentMethodType.NONE)
			errorMessage += '• ' + t("payment.method_not_fount") + '\n';
		return errorMessage.trim() || null;
	}

	const handleDonate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();

		const errorMsg = validate();
		if (errorMsg !== null) {
			toast.error(errorMsg, { style: { whiteSpace: "pre-line" } });
			return;
		}

		try {
			const response = await PayApi.donate({
				amount,
				currency: "RUB",
				paymentMethod: method
			});

			if (response.status !== 200) {
				toast.error(t('errors.server_request_failed'));
				return;
			}

			const data = response.data as JsonRpcResponse<string>;
			if (data.status !== 'success') {
				toast.error(data.errors.map(error => t(error)).join('\n'));
				return;
			}

			window.open(data.body, '_blank');
		} catch (error) {
			toast.error(t('errors.unexpected_error'));
		}
	}

	useEffect(() => {
		updateSlider(amount);
		calculatePrices();
	}, [amount]);

	useEffect(() => {
		updatePaymentSystem();
	}, []);

	return (
		<>
			<div className="bg donnation"></div>
			<div className="dark-container">
				<div className="container-head">
					<p className="title">Пополнения</p>
					<Link to="/donation-history" className="head-link">
						<img src="/images/icon/calendar.svg" alt="calendar" />
						История
					</Link>
				</div>
				<div className="balance-input">
					<div className="donat-input">
						<label htmlFor="balanceInput">Укажите баланс:</label>
						<input
							type="number"
							id="balanceInput"
							value={amount}
							min="1"
							max="500000"
							onChange={handleInputChange}
						/>
					</div>
					<div className="bonus-display">
						<img src="/images/icon/coin.svg" alt="coin" width="14" height="14" />
						<span id="bonusDisplay">+{Math.round(bonus)} бонус</span>
					</div>
				</div>
				<div className="range-container">
					<input
						type="range"
						min="1"
						max="200000"
						value={amount}
						className="slider"
						id="balanceSlider"
						onChange={handleSliderChange}
					/>
					<div className="range-labels">
						{valuesAndBonuses.map((item, index) => (
							<div key={index} className={getLabelClass(item.value)}>
								{item.value} <span className="bonus">bonus +{item.bonus}</span>
							</div>
						))}
					</div>
				</div>
				<div className="convert-money">
					<div className="convert-item"><p>USD</p><p>{parseFloat(prices.usd.toFixed(2))}</p></div>
					<div className="convert-item"><p>RUB</p><p>{parseFloat(prices.rub.toFixed(2))}</p></div>
					<div className="convert-item"><p>EUR</p><p>{parseFloat(prices.eur.toFixed(2))}</p></div>
					<div className="convert-item"><p>UAH</p><p>{parseFloat(prices.uah.toFixed(2))}</p></div>
				</div>
				<div className="container-head">
					<p className="title">Способы оплаты</p>
				</div>
				<div className="payments-method">
					{optionsList.map((option, index) => (
						<div key={index} onClick={(e) => handleSetPaymentOption(e, index)} className={`payment-item ${selectedOptionIndex === index ? "active" : ""}`}>
							<img src={getPaymentMethodIcon(option.option)} alt="" />
						</div>
					))}
				</div>
				<div className="container-head">
					<p className="title">Платежная система</p>
				</div>
				<div className="payments-method">
					{optionsList[selectedOptionIndex]?.paymentMethods.map((option, index) => (
						<div key={index} onClick={(e) => handleSetPaymentMethod(e, index, option)} className={`payment-item ${selectedMethodIndex === index ? "active" : ""}`}>
							<img src={getImagePathForMethodType(option.type)} alt="" />
						</div>
					))}
				</div>
				<button type="button" onClick={handleDonate} className="cta-secondary big"><span>Купить</span></button>
			</div>
		</>
	);
}

export default Donation;