import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Activation() {
	const location = useLocation();
    const { email } = location.state || {};

	return (
		<div className="notification-full">
			<div className="notification-content">
				<h2 className="accent">Ожидание активации аккаунта</h2>
				<p>
					На Ваш почтовый адрес <span className="accent">{email}</span> направлено письмо с
					дальнейшей инструкцией по регистрации.
				</p>
				<p>
					Пройдите по ссылке в письме для активации аккаунта. Если письмо не
					пришло проверьте папку спам.
				</p>
				<div className="link-group">
					<Link to="/login">Вход</Link>
				</div>
			</div>
		</div>
	);
}

export default Activation;